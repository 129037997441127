import React, { useState, useEffect, useRef, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import UIButton from "../atoms/UIButton";

import imgItem0 from '../../assets/images/banner-hero/img_banner_hero_000.webp';
import imgItem1 from '../../assets/images/banner-hero/img_banner_hero_001.webp';
import imgItem2 from '../../assets/images/banner-hero/img_banner_hero_002.webp';
import imgItem3 from '../../assets/images/banner-hero/img_banner_hero_003.webp';
import imgItem4 from '../../assets/images/banner-hero/img_banner_hero_004.webp';
import imgItem5 from '../../assets/images/banner-hero/img_banner_hero_005.webp';

import web_sites_white from '../../assets/images/icons/icon_web_sites_white.webp'; 
import web_sites_yellow from '../../assets/images/icons/icon_web_sites_yellow.webp';
import apps_white from '../../assets/images/icons/icon_applications_white.webp'; 
import apps_yellow from '../../assets/images/icons/icon_applications_yellow.webp';
import blog_white from '../../assets/images/icons/icon_blog_white.webp'; 
import blog_yellow from '../../assets/images/icons/icon_blog_yellow.webp';
import ecommerce_white from '../../assets/images/icons/icon_ecommerce_white.webp'; 
import ecommerce_yellow from '../../assets/images/icons/icon_ecommerce_yellow.webp';
import games_white from '../../assets/images/icons/icon_games_white.webp'; 
import games_yellow from '../../assets/images/icons/icon_games_yellow.webp';
import store_white from '../../assets/images/icons/icon_store_white.webp'; 
import store_yellow from '../../assets/images/icons/icon_store_yellow.webp';

const BannerHero = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const [currentSlide, setCurrentSlide] = useState(0);
    const intervalRef = useRef(null);

    const routes = [
        '/web-design',
        'https://enlacewebdesign.com/ecommerce/',
        '/apps',
        '/games',
        '/store',
        '/blog'
    ];

    const handleNavigation = useCallback(() => {
        const route = routes[currentSlide];
        
        if (route.startsWith('http')) {
            window.open(route, "_blank");
        } else {
            navigate(route);
        }
    }, [currentSlide, navigate]);

    const slides = [
        {
            title: t('00-SliderTitle'),
            subtitle: t('00-SliderSubtitle'),
            text: t('00-SliderText'),
            buttonText: t('00-Btn'),
            imgSrc: '/images/banner-home/img_banner_hero_000.webp',
            imgAlt: t('00-altImg'),
            indicator: t('00-indicator'),
            indicatorImageActive: web_sites_yellow,
            indicatorImageNormal: web_sites_white
        },
        {
            title: t('01-SliderTitle'),
            subtitle: t('01-SliderSubtitle'),
            text: t('01-SliderText'),
            buttonText: t('01-Btn'),
            imgSrc: imgItem1,
            imgAlt: t('01-altImg'),
            indicator: t('01-indicator'),
            indicatorImageActive: ecommerce_yellow,
            indicatorImageNormal: ecommerce_white
        },
        {
            title: t('02-SliderTitle'),
            subtitle: t('02-SliderSubtitle'),
            text: t('02-SliderText'),
            buttonText: t('02-Btn'),
            imgSrc: imgItem2,
            imgAlt: t('02-altImg'),
            indicator: t('02-indicator'),
            indicatorImageActive: apps_yellow,
            indicatorImageNormal: apps_white
        },
        {
            title: t('03-SliderTitle'),
            subtitle: t('03-SliderSubtitle'),
            text: t('03-SliderText'),
            buttonText: t('03-Btn'),
            imgSrc: imgItem3,
            imgAlt: t('03-altImg'),
            indicator: t('03-indicator'),
            indicatorImageActive: games_yellow,
            indicatorImageNormal: games_white
        },
        // {
        //     title: t('04-SliderTitle'),
        //     subtitle: t('04-SliderSubtitle'),
        //     text: t('04-SliderText'),
        //     buttonText: t('04-Btn'),
        //     imgSrc: imgItem4,
        //     imgAlt: t('04-altImg'),
        //     indicator: t('04-indicator'),
        //     indicatorImageActive: store_yellow,
        //     indicatorImageNormal: store_white
        // },
        {
            title: t('05-SliderTitle'),
            subtitle: t('05-SliderSubtitle'),
            text: t('05-SliderText'),
            buttonText: t('05-Btn'),
            imgSrc: imgItem5,
            imgAlt: t('05-altImg'),
            indicator: t('05-indicator'),
            indicatorImageActive: blog_yellow,
            indicatorImageNormal: blog_white
        }
    ];

    useEffect(() => {
        const startInterval = () => {
            intervalRef.current = setInterval(() => {
                setCurrentSlide((prevSlide) => (prevSlide + 1) % slides.length);
            }, 12000);
        };

        startInterval();

        return () => clearInterval(intervalRef.current);
    }, [slides.length]);

    const resetInterval = () => {
        if (intervalRef.current) {
            clearInterval(intervalRef.current);
            const startInterval = () => {
                intervalRef.current = setInterval(() => {
                    setCurrentSlide((prevSlide) => (prevSlide + 1) % slides.length);
                }, 20000);
            };
            startInterval();
        }
    };

    const handlePrevSlide = () => {
        setCurrentSlide((prevSlide) => (prevSlide - 1 + slides.length) % slides.length);
        resetInterval();
    };

    const handleNextSlide = () => {
        setCurrentSlide((prevSlide) => (prevSlide + 1) % slides.length);
        resetInterval();
    };

    const handleIndicatorClick = (index) => {
        setCurrentSlide(index);
        resetInterval();
    };

    return (
        <div className="relative w-full xl:max-w-[1920px] min-h-[750px] md:min-h-[600px] lg:min-h-[600px] xl:min-h-[840px]">
            {slides.map((slide, index) => (
                <div
                    key={index}
                    className={`absolute left-[50%] translate-x-[-50%] top-[50%] p-5 transform -translate-y-1/2 w-10/12 md:w-full transition-opacity duration-1000 ${index === currentSlide ? 'opacity-100' : 'opacity-0'}`}
                >
                    <div className="flex items-center justify-center w-full">
                        <div className="w-10/12 m-auto flex flex-col md:flex-row items-center">
                            <div className="w-full md:w-7/12 flex flex-col items-center justify-center py-8 text-center gap-2 xl:gap-0">
                                <h1 className='text-2xl md:text-4xl xl:text-[60px] xl:leading-[68px] font-bold mb-2 text-brand-primary'>{slide.title}</h1>
                                <p className='text-base md:text-3xl xl:text-[38px] xl:leading-[40px] mb-2 text-white font-bold'>{slide.subtitle}</p>
                                <p className='text-md md:text-lg xl:text-[28px] xl:leading-[30px] text-center mb-4 text-white'>{slide.text}</p>
                                <UIButton
                                    primary
                                    scrollToTop
                                    children={slide.buttonText}
                                    action={handleNavigation} 
                                />
                            </div>
                            <div className="w-full md:w-5/12 flex justify-center items-center">
                                <img 
                                    width={530} height={424} 
                                    className="w-full h-auto object-cover" 
                                    src={slide.imgSrc}
                                    alt={slide.imgAlt} 
                                    loading={index === 0 ? "eager" : "lazy"} 
                                    fetchpriority={index === 0 ? "high" : "auto"}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            ))}

            <UIButton
                arrowLeftWhite
                className={'w-8 h-8 absolute left-4 top-1/2 transform -translate-y-1/2 z-20'}
                action={handlePrevSlide}
                children={' '}
            />  
            <UIButton
                arrowRightWhite
                className={'w-8 h-8 absolute right-4 top-1/2 transform -translate-y-1/2 z-20'}
                action={handleNextSlide}
                children={' '}
            />

            <div className="w-full md:w-10/12 lg:w-8/12 flex justify-center md:justify-between px-5 md:p-0 absolute bottom-4 left-1/2 transform -translate-x-1/2 space-x-2 z-20 gap-4 xl:gap-6">
                {slides.map((slide, index) => (
                    <div
                        key={index}
                        onClick={() => handleIndicatorClick(index)}
                        className="flex items-center cursor-pointer group"
                    >
                        <img
                            src={index === currentSlide ? slide.indicatorImageActive : slide.indicatorImageNormal}
                            alt="Indicator Icon"
                            className="w-full md:w-auto h-10 md:h-[32px] xl:h-[50px] object-contain md:object-cover group-hover:opacity-0 group-hover:hidden"
                        />
                        <img
                            src={slide.indicatorImageActive}
                            alt="Indicator Icon Active"
                            className="w-full md:w-auto h-10 md:h-[32px] xl:h-[50px] object-contain md:object-cover hidden group-hover:block"
                        />
                        <p
                            className={`ml-1 hidden md:block text-xs lg:text-xl xl:text-2xl text-center ${index === currentSlide ? 'text-brand-primary' : 'text-white'} group-hover:text-brand-primary`}
                        >
                            {slide.indicator}
                        </p>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default BannerHero;
