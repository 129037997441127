import React from "react";
import { FaRocket } from "react-icons/fa";

const Loader = () => {
    return (
        <div className="w-full h-auto flex justify-center items-center">
            <div className="flex flex-col items-center">
                <FaRocket className="text-yellow-500 text-6xl animate-bounce" />
                <p className="text-white mt-4 text-xl font-semibold">Cargando...</p>
            </div>
        </div>
    );
};

export default Loader;
