import React from "react";
import { useTranslation } from "react-i18next";

const UIButton = ({
    arrowRightYellow,
    arrowLeftWhite,
    arrowRightWhite,
    disabled,
    primary,
    primaryIcon,
    secondary,
    whatsapp,
    whatsappNumber,
    url,
    scrollToTop,
    className,
    children,
    action,
    // ariaLabel = "",
}) => {
    const { t } = useTranslation();

    return (
        <button
            disabled={disabled}
            aria-label={"botón"}
            className={`
                outline-none
                ${className}
                ${disabled && "bg-slate-500"}
                ${primary && "bg-yellow-500 rounded-full px-5 py-1 text-black font-bold"}
                ${primaryIcon && "border border-yellow-500 rounded-full px-12 py-2 xl:px-16 xl:py-6 text-yellow-500 text-[10px] md:text-base xl:text-[35px] font-bold bg-icon-eye bg-[length:10px_10px] xl:bg-[length:35px_35px] bg-contain-[15px] bg-[center_right_10px] xl:bg-[center_right_10px] bg-no-repeat"}
                ${secondary && "bg-trasparent rounded-full border border-yellow-500 px-5 py-1 text-yellow-500 font-bold"}
                ${whatsapp && "fixed right-2 bottom-[10%] w-[40px] md:w-[50px] h-[40px] md:h-[50px] z-999 bg-icon-whatsapp bg-cover cursor-pointer"}
                ${arrowRightYellow && "w-6 h-6 bg-icon-arrow-right-yellow bg-contain bg-no-repeat"}
                ${arrowLeftWhite && "bg-icon-circle-left-white bg-contain bg-no-repeat"}
                ${arrowRightWhite && "bg-icon-circle-right-white bg-contain bg-no-repeat"}
            `}
            onClick={() => {
                if (scrollToTop) {
                    window.scrollTo({
                        top: 0,
                        behavior: "smooth",
                    });
                }

                if (action) {
                    action();
                } else if (whatsappNumber) {
                    window.open(`https://api.whatsapp.com/send?phone=${whatsappNumber}`, "_blank");
                } else if (url) {
                    window.open(url, "_blank");
                }
            }}
        >
            {children || t("FormButton")}
        </button>
    );
};

export default UIButton;
