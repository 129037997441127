import React, { useState, useEffect } from "react";

const Carousel = ({ slides, autoPlay = true, autoPlayInterval = 8000 }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const nextSlide = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === slides.length - 1 ? 0 : prevIndex + 1
    );
  };

  const prevSlide = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? slides.length - 1 : prevIndex - 1
    );
  };

  useEffect(() => {
    if (!autoPlay) return;

    const interval = setInterval(() => {
      nextSlide();
    }, autoPlayInterval);

    return () => clearInterval(interval);
  }, [currentIndex, autoPlay, autoPlayInterval]);

  return (
    <div className="relative w-full overflow-hidden">
      {/* Slider */}
      <div
        className="flex transition-transform duration-500 ease-in-out"
        style={{ transform: `translateX(-${currentIndex * 100}%)` }}
      >
        {slides.map((slide, index) => (
          <div
            key={index}
            className="w-full flex-shrink-0 relative flex justify-center items-center"
          >
            <img
              src={slide.image}
              alt={`Slide ${index}`}
              className="w-full h-96 object-cover md:h-80 lg:h-[460px]"
            />
            <div className="absolute inset-0 bg-black bg-opacity-80 lg:bg-opacity-40 flex justify-center items-center">
              <div className="w-9/12 px-5 lg:px-10 text-center lg:text-left">
                <p className="w-full lg:w-[50%] text-white text-[24px] leading-[26px] md:text-[40px] md:leading-[44px] lg:text-[50px] lg:leading-[54px] font-semibold">
                  {slide.text}
                </p>
                {slide.lead && (
                  <p className="text-white text-xs md:text-xl py-4">{slide.lead}</p>
                )}
              </div>
            </div>
          </div>
        ))}
      </div>

      {/* Controles */}
      <button
        onClick={prevSlide}
        className="absolute top-1/2 left-4 transform -translate-y-1/2 bg-gray-800 text-white p-2 rounded-full hover:bg-gray-600 focus:outline-none"
      >
        &#10094;
      </button>
      <button
        onClick={nextSlide}
        className="absolute top-1/2 right-4 transform -translate-y-1/2 bg-gray-800 text-white p-2 rounded-full hover:bg-gray-600 focus:outline-none"
      >
        &#10095;
      </button>

      {/* Indicadores */}
      <div className="absolute bottom-4 left-1/2 transform -translate-x-1/2 flex space-x-4">
        {slides.map((_, index) => (
          <div
            key={index}
            onClick={() => setCurrentIndex(index)}
            className={`w-4 h-4 md:w-6 md:h-6 rounded-full ${
              currentIndex === index ? "bg-yellow-500" : "bg-gray-400"
            } cursor-pointer`}
          ></div>
        ))}
      </div>
    </div>
  );
};

export default Carousel;
