import React, { useState, useEffect, lazy, Suspense } from "react";
import { HelmetProvider } from "react-helmet-async";
import './i18n/i18n';
import UIButton from "./components/atoms/UIButton";
import buttonUp from "./assets/images/demo-explorer/images/icons/icon_up.webp";
import Loader from "./components/molecules/loader/Loader";

const LazyRutas = lazy(() => import("./router/Rutas"));

function App() {
  const [showButton, setShowButton] = useState(false);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 800) {
        setShowButton(true);
      } else {
        setShowButton(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  // Integración de Google Tag Manager
  useEffect(() => {
    const loadGTM = () => {
      const script = document.createElement("script");
      script.src = "https://www.googletagmanager.com/gtag/js?id=G-DDJ6WSX61M";
      script.async = true;
      script.defer = true;
      document.head.appendChild(script);

      script.onload = () => {
        window.dataLayer = window.dataLayer || [];
        function gtag() {
          window.dataLayer.push(arguments);
        }
        gtag("js", new Date());
        gtag("config", "G-DDJ6WSX61M", { send_page_view: false });
      };
    };

    loadGTM();
  }, []);

  return (
    <React.StrictMode>
      <HelmetProvider>
        {/* Botón Scroll to Top */}
        {showButton && (
          <UIButton
            className={"w-12 md:w-20"}
            action={scrollToTop}
            children={
              <img
                width="40"
                height="40"
                className={`w-12 h-auto fixed right-2 z-30 top-[60%] transition-opacity duration-500 ease-in-out 
                  ${showButton ? "opacity-100 translate-y-0" : "opacity-0 translate-y-4 pointer-events-none"}`}
                src={buttonUp}
                alt="Scroll to top"
              />
            }
          />
        )}

        {/* Contenido Principal */}
        <Suspense fallback={<Loader />}>
          <div className="bg-home-pattern-desktop sm:bg-home-pattern-tablet lg:bg-home-pattern-mobile bg-fixed bg-cover h-full z-10">
            <LazyRutas />
          </div>
        </Suspense>
      </HelmetProvider>
    </React.StrictMode>
  );
}

export default App;
