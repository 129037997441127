import React, { useState, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Brand from "../atoms/Brand";
import imgBrand from "../../assets/images/brand/brand_positive.webp";
import { useTranslation } from "react-i18next";
import menu from "../../assets/images/icons/icon_menu.webp";
import UIButton from "../atoms/UIButton";

const Header = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [isScrolled, setIsScrolled] = useState(false);

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    const closeMenu = () => {
        setIsMenuOpen(false);
    };

    const toggleDropdown = () => {
        setIsDropdownOpen(!isDropdownOpen);
    };

    const goHome = () => {
        navigate("/");
    };

    const goContact = () => {
        navigate("/contact");
    };

    useEffect(() => {
        const handleScroll = () => {
            setIsScrolled(window.scrollY > 70);
        };

        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    const location = useLocation();
    const isActive = (path) => {
        return location.pathname === path;
    };


    return (
        <div
            className={`fixed top-0 left-0 flex justify-between items-center w-full py-2 px-8 shadow lg:flex-row z-50 transition-all duration-300 ${isScrolled ? "bg-[#00011860] h-[60px]" : "bg-[#00011895] h-[80px] xl:h-[100px]"
                }`}
        >
            <div className="w-1/3 h-auto">
                <Brand
                    action={goHome}
                    className={`transition-all duration-300 ${isScrolled ? "w-[100px] xl:w-[150px]" : "w-[120px] xl:w-[220px]"
                        }`}
                    src={imgBrand}
                />
            </div>
            <div className="lg:hidden">
                <button title={"Menu"} onClick={toggleMenu}>
                    <img src={menu} alt="menu" />
                </button>
                {isMenuOpen && (
                    <div className="fixed top-0 right-0 w-full h-screen bg-black bg-opacity-80 z-10" onClick={closeMenu}>
                        <div className="flex w-full justify-end">
                            <div className="relative flex flex-col gap-2 bg-white w-1/2 h-screen items-center p-4 mx-0 pt-20" onClick={(e) => e.stopPropagation()}>
                                <a className="text-slate-800 hover:text-yellow-500 text-base" href="/home">{t('HeaderHome')}</a>
                                <a className="text-slate-800 hover:text-yellow-500 text-base" href="/about-us">{t('HeaderAbout')}</a>
                                <a className="text-slate-800 hover:text-yellow-500 text-base" href="/web-design">{t('HeaderSites')}</a>
                                <a className="text-slate-800 hover:text-yellow-500 text-base" href="https://enlacewebdesign.com/ecommerce/" target="_blank">{t('HeaderEcommerce')}</a>
                                <a className="text-slate-800 hover:text-yellow-500 text-base" href="/apps">{t('HeaderApps')}</a>
                                {/* <a className="text-slate-800 hover:text-yellow-500 text-base" href="/store">{t('HeaderStore')}</a> */}
                                <a className="text-slate-800 hover:text-yellow-500 text-base" href="/blog">{t('HeaderBlog')}</a>
                                <a className="text-slate-800 hover:text-yellow-500 text-base" href="/contact">{t('HeaderContact')}</a>
                                <div className="absolute top-0 left-2">
                                    <a className="text-slate-800" href="#" onClick={toggleMenu}>X</a>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
            <div className="w-2/3 hidden lg:flex gap-8 justify-end md:items-center">
                <a
                    className={`text-lg md:text-sm xl:text-2xl ${isActive("/home")
                            ? "text-yellow-500 font-bold"
                            : isScrolled
                                ? "text-slate-200"
                                : "text-white"
                        } hover:text-yellow-500`}
                    href="/home"
                >
                    {t("HeaderHome")}
                </a>
                <a
                    className={`text-lg md:text-sm xl:text-2xl ${isActive("/about-us")
                            ? "text-yellow-500 font-bold"
                            : isScrolled
                                ? "text-slate-200"
                                : "text-white"
                        } hover:text-yellow-500`}
                    href="/about-us"
                >
                    {t("HeaderAbout")}
                </a>
                <div
                    className="relative"
                    onMouseEnter={() => setIsDropdownOpen(true)}
                    onMouseLeave={() => setIsDropdownOpen(false)}
                >
                    <button
                        className={`nav-link text-lg md:text-sm xl:text-2xl ${isActive("/web-design") || isActive("/apps") || isActive("/games")
                                ? "text-yellow-500 font-bold"
                                : isScrolled
                                    ? "text-slate-200"
                                    : "text-white"
                            } hover:text-yellow-500`}
                    >
                        {t("HeaderServices")}
                    </button>
                    <ul
                        className={`absolute mt-2 bg-white shadow-md rounded-md p-4 transition-all duration-300 ${isDropdownOpen ? "opacity-100 visible" : "opacity-0 invisible"
                            }`}
                    >
                        <li className="border-b-2 py-2">
                            <a
                                className={`text-base ${isActive("/web-design")
                                        ? "text-yellow-500 font-bold"
                                        : "text-slate-800"
                                    } hover:text-yellow-500`}
                                href="/web-design"
                            >
                                {t("HeaderSites")}
                            </a>
                        </li>
                        <li className="border-b-2 py-2">
                            <a
                                className="text-base text-slate-800 hover:text-yellow-500"
                                href="https://enlacewebdesign.com/ecommerce/"
                                target="_blank"
                            >
                                {t("HeaderEcommerce")}
                            </a>
                        </li>
                        <li className="border-b-2 py-2">
                            <a
                                className={`text-base ${isActive("/apps")
                                        ? "text-yellow-500 font-bold"
                                        : "text-slate-800"
                                    } hover:text-yellow-500`}
                                href="/apps"
                            >
                                {t("HeaderApps")}
                            </a>
                        </li>
                        <li className="border-b-2 py-2">
                            <a
                                className={`text-base ${isActive("/games")
                                        ? "text-yellow-500 font-bold"
                                        : "text-slate-800"
                                    } hover:text-yellow-500`}
                                href="/games"
                            >
                                {t("HeaderGames")}
                            </a>
                        </li>
                    </ul>
                </div>
                <a
                    className={`text-lg md:text-sm xl:text-2xl ${isActive("/blog")
                            ? "text-yellow-500 font-bold"
                            : isScrolled
                                ? "text-slate-200"
                                : "text-white"
                        } hover:text-yellow-500`}
                    href="/blog"
                >
                    {t("HeaderBlog")}
                </a>
                <UIButton
                    scrollToTop
                    primary
                    children={t("HeaderContact")}
                    action={goContact}                   
                />
            </div>

        </div>
    );
};

export default Header;
