import React from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from '../components/views/Home';
import WebDesign from '../components/views/WebDesign';
import Games from '../components/views/Games';
import Store from '../components/views/Store';
import Blog from '../components/views/Blog';
import BlogArticle from '../components/views/BlogArticle';
import Apps from '../components/views/Apps';
import Contact from '../components/views/Contact';
import AboutUs from '../components/views/AboutUs';
import Profile from '../components/views/Profile';

const Rutas = () => {
    return (
        <div>
            <BrowserRouter>
                <Routes>
                    <Route path='/' element={<Home />} />
                    <Route path='/home' element={<Home />} />
                    <Route path='/about-us' element={<AboutUs />} />
                    <Route path='/profile' element={<Profile />} />
                    <Route path='/apps' element={<Apps />} />
                    <Route path='/web-design' element={<WebDesign />} />
                    <Route path='/games' element={<Games />} />
                    <Route path='/store' element={<Store />} />
                    <Route path='/blog' element={<Blog />} />
                    <Route path="/blog/:slug" element={<BlogArticle />} />
                    <Route path='/contact' element={<Contact />} />
                </Routes>
            </BrowserRouter>
        </div>
    );
}

export default Rutas;
