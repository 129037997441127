import React, { Suspense, lazy } from "react";
import { Helmet } from "react-helmet-async";
import UIButton from "../atoms/UIButton";
import CardService from "../molecules/cards/CardService";
import { FormContact } from "../molecules/forms/FormContact";
import BannerHero from "../organisms/BannerHero";
import Header from "../organisms/Header";
import { useTranslation } from "react-i18next";
import Loader from "../molecules/loader/Loader";

const SliderItems = lazy(() => import("../molecules/sliders/SliderItems"));
const CardTest = lazy(() => import("../molecules/cards/CardTest"));
const SocialContacts = lazy(() => import("../organisms/SocialContacts"));

const Home =()=>{
    
    const { t } = useTranslation();

    return (
        <>
            <Helmet>
                <title>Inicio | Enlace Web Design</title>
                <meta
                    name="description"
                    content="Enlace Web Design: Creamos sitios web responsive, personalizados y optimizados para ofrecer la mejor experiencia de usuario. Desarrollamos aplicaciones y soluciones web innovadoras que impulsan tu negocio."
                />
                <meta
                    name="keywords"
                    content="Enlace Web Design, diseño web, desarrollo web, sitios web responsive, creación de páginas web, optimización de sitios web, experiencia de usuario, soluciones web personalizadas, desarrollo de aplicaciones web, agencia de desarrollo web, diseño y desarrollo web profesional, innovación digital"
                />
            </Helmet>

            <Header />
            <div className="flex justify-center">
                <BannerHero />                
            </div>
            <div className="w-10/12 xl:max-w-[1440px] m-auto flex flex-col-reverse md:flex-row justify-center py-20 ">
                <div className="w-full md:w-1/2">
                    <CardService />
                </div>
                <div className="w-full md:w-1/2 px-2 md:px-10">
                    <p className="text-white text-center pb-10">
                        {t('HomeContactTitle')}                        
                    </p>
                    <FormContact />
                </div>
            </div>
            <div id="aboutUs"></div>
            <div className="w-10/12 lg:w-6/12 xl:max-w-[1440px] m-auto flex flex-col items-center py-20 text-center gap-8">
                <p className="text-white font-normal text-base">
                    {t('HomeParagraphCompromise')}                    
                </p>
                <p className="text-white font-bold text-2xl">
                    {t('HomeTitleGrowth')}                    
                </p>
                <UIButton 
                    primary
                    children={<>{t('HomeButtonDownload')}</>}
                    url={'https://enlacewebdesign.com/portafolio/brochure_enlace-web-design-es-2024.pdf'}
                />
                <p className="text-white font-normal text-base">
                    {t('HomeParagraphResponsive')}                    
                </p>
            </div>
            {/* Lazy Loaded Components */}
            <Suspense fallback={<Loader />}>
                <div className="w-10/12 xl:max-w-[1440px] m-auto flex flex-col items-center justify-center py-20">
                    <p className="text-white font-bold text-2xl mb-20">
                        {t('HomeParagraphTechnologies')}
                    </p>
                    <SliderItems />
                </div>
            </Suspense>

            <Suspense fallback={<Loader />}>
                <div className="w-10/12 xl:max-w-[1440px] m-auto flex justify-center py-20">
                    <CardTest />
                </div>
            </Suspense>
            <Suspense fallback={<Loader />}>
                <SocialContacts />
            </Suspense>
        </>
    )
}
export default Home;