import React from 'react';
import {
    Chart as ChartJS,
    RadialLinearScale,
    PointElement,
    LineElement,
    Filler,
    Tooltip,
    Legend,
} from 'chart.js';
import { Radar } from 'react-chartjs-2';
import { FaGitlab, FaBehance, FaLinkedin } from 'react-icons/fa';
import { SiFigma, SiAdobecreativecloud, SiCinema4D, SiAngular, SiReact, SiHtml5, SiCss3, SiUnity, SiSketch, SiJavascript, SiGit, SiLogitechg, SiWordpress, SiUnrealengine, SiAndroidstudio, SiXcode, SiLogitech, } from 'react-icons/si';
import UIButton from '../atoms/UIButton';
import icon_015 from "../../assets/images/icons/icon_015.webp";
import icon_016 from "../../assets/images/icons/icon_016.webp";
import icon_017 from "../../assets/images/icons/icon_017.webp";
import icon_018 from "../../assets/images/icons/icon_018.webp";
import icon_019 from "../../assets/images/icons/icon_019.webp";
import robot_011 from "../../assets/images/robots/robot_011.png";
import Header from '../organisms/Header';

// Registra los componentes de Chart.js
ChartJS.register(RadialLinearScale, PointElement, LineElement, Filler, Tooltip, Legend);

const Profile = () => {
    const personalData = {
        name: "Juan Pérez",
        email: "juan.perez@enlacewebdesign.com",
        phone: "+57 123 456 7890",
        location: "Bogotá, Colombia",
        photo: "https://via.placeholder.com/150",
    };

    const professionalProfile = <p>
        Soy un Diseñador Gráfico UX/UI Senior con más de 10 años de experiencia diseñando interfaces atractivas y funcionales.
        Apasionado por crear experiencias de usuario intuitivas que cumplan con los objetivos del cliente.
        <br /><br />
        Diseñador con ideas creativas que siempre buscan aportar valor y buenas experiencias buscando siempre la mejor usabilidad y diseñar los productos  enfocados en el usuario.
    </p>;

    const whatIDo = `
        Diseño de interfaces intuitivas, creación de sistemas de diseño y prototipos interactivos. Colaboro estrechamente con equipos de desarrollo
        para garantizar que las soluciones diseñadas cumplan con las expectativas del cliente y los usuarios.
    `;

    const workExperience = [
        {
            company: "Agencia Creativa XYZ",
            role: "Diseñador UX/UI",
            period: "2015 - 2020",
            description: "Diseño de aplicaciones web y móviles para clientes internacionales. Responsable de la identidad visual y prototipos interactivos."
        },
        {
            company: "Creative Studio ABC",
            role: "Junior Designer",
            period: "2013 - 2015",
            description: "Apoyo en proyectos gráficos y diseño de branding corporativo."
        },
        {
            company: "Freelance",
            role: "Freelance Designer",
            period: "2010 - 2013",
            description: "Proyectos independientes de diseño gráfico y desarrollo de identidad visual."
        },
        {
            company: "Tech Innovators",
            role: "Diseñador Gráfico Senior",
            period: "2020 - Presente",
            description: "Liderar proyectos de diseño UX/UI para plataformas SaaS, colaborando con equipos de desarrollo y marketing."
        },
    ];

    const portfolioProjects = [
        {
            image: "https://via.placeholder.com/300x200",
            title: "Proyecto 1",
            description: "Rediseño de una aplicación móvil para una startup fintech."
        },
        {
            image: "https://via.placeholder.com/300x200",
            title: "Proyecto 2",
            description: "Diseño de un sitio web para una empresa de moda sostenible."
        },
        {
            image: "https://via.placeholder.com/300x200",
            title: "Proyecto 3",
            description: "Creación de un sistema de diseño para una plataforma educativa."
        }
    ];

    const studies = [
        {
            degree: "Maestría en Diseño Gráfico",
            institution: "Universidad Nacional de Colombia",
            year: "2015"
        },
        {
            degree: "Licenciatura en Diseño Gráfico",
            institution: "Universidad de los Andes",
            year: "2010"
        }
    ];

    const languages = [
        {
            language: "Español",
            level: "Nativo"
        },
        {
            language: "Inglés",
            level: "Avanzado"
        },
        {
            language: "Francés",
            level: "Intermedio"
        }
    ];

    const radarData = {
        labels: ["Impresión", "Marketing", "Diseño UX", "Diseño UI", "Liderazgo"],
        datasets: [
            {
                label: "Habilidades",
                data: [8, 6, 9, 9, 10],
                backgroundColor: "rgba(255, 206, 86, 0.2)",
                borderColor: "rgba(255, 206, 86, 1)",
                borderWidth: 2,
            },
        ],
    };

    const radarOptions = {
        scales: {
            r: {
                max: 10,
                min: 0,
                angleLines: {
                    display: true,
                    color: "rgba(255, 255, 255, 0.3)",
                },
                grid: {
                    color: "rgba(255, 255, 255, 0.1)",
                },
                pointLabels: {
                    color: "#ffffff",
                    font: {
                        size: 14,
                    },
                },
                ticks: {
                    display: false,
                    stepSize: 2,
                    color: "#ffffff",
                },
            },
        },
        plugins: {
            legend: {
                labels: {
                    color: "#ffffff",
                },
            },
        },
    };

    return (
        <React.Fragment>
            <Header />
            <div className="w-full lg:w-10/12 mx-auto py-10 grid grid-cols-1 md:grid-cols-3  md:gap-10 pt-40">
                <div className="col-span-1 flex flex-col bg-gray-800 p-6 rounded-lg">
                    <div className="flex flex-col items-center gap-4">
                        <img
                            src={personalData.photo}
                            alt={personalData.name}
                            className="w-32 h-32 rounded-full object-cover border-2 border-yellow-500"
                        />
                        <div className="text-center">
                            <h1 className="text-yellow-500 text-2xl font-bold">{personalData.name}</h1>
                            <p className="text-gray-300 font-bold">Email: <span className='font-normal'>{personalData.email}</span></p>
                            {/* <p className="text-gray-300">Teléfono: {personalData.phone}</p> */}
                            <p className="text-gray-300">Ubicación: {personalData.location}</p>
                        </div>
                    </div>

                    <div className="flex justify-center gap-4 mt-6">
                        <UIButton
                            url="https://gitlab.com"
                            target="_blank"
                            className="text-yellow-500 hover:text-white"
                            children={<FaGitlab size={24} />}
                        />
                        <UIButton
                            url="https://behance.net"
                            target="_blank"
                            className="text-yellow-500 hover:text-white"
                            children={<FaBehance size={24} />}
                        />
                        <UIButton
                            url="https://linkedin.com"
                            target="_blank"
                            className="text-yellow-500 hover:text-white"
                            children={<FaLinkedin size={24} />}
                        />
                    </div>
                    <div className="mt-10">
                        <div className='w-full h-[1px] my-4 bg-gradient-to-r from-purple-400 to-cyan-400'></div>
                        <h2 className="text-yellow-500 text-xl font-bold">Me especializo en:</h2>
                        <p className="text-gray-300 mt-4">{whatIDo}</p>
                        <div className="mt-6">
                            <Radar data={radarData} options={radarOptions} />
                        </div>
                    </div>
                    <div className='w-full h-[1px] my-4 bg-gradient-to-r from-purple-400 to-cyan-400'></div>
                    <div className="mt-10">
                        <h2 className="text-yellow-500 text-xl font-bold">Programas que manejo</h2>
                        <p className="text-gray-300 mt-4 font-bold">Diseño gráfico:</p>
                        <div className="flex flex-wrap gap-6 mt-4 justify-start">
                            <div className="flex flex-col items-center">
                                <SiFigma size={40} className="text-yellow-500 hover:text-white" />
                                <p className="text-gray-300 mt-2 text-sm">Figma</p>
                            </div>
                            <div className="flex flex-col items-center">
                                <SiAdobecreativecloud size={40} className="text-yellow-500 hover:text-white" />
                                <p className="text-gray-300 mt-2 text-sm">Adobe Cloud</p>
                            </div>
                            <div className="flex flex-col items-center">
                                <SiSketch size={40} className="text-yellow-500 hover:text-white" />
                                <p className="text-gray-300 mt-2 text-sm">Sketch</p>
                            </div>
                        </div>
                        <p className="text-gray-300 mt-4 font-bold">Desarrollo Web:</p>
                        <div className="flex flex-wrap gap-6 mt-4 justify-start">
                            <div className="flex flex-col items-center">
                                <SiAngular size={40} className="text-yellow-500 hover:text-white" />
                                <p className="text-gray-300 mt-2 text-sm">Angular</p>
                            </div>
                            <div className="flex flex-col items-center">
                                <SiReact size={40} className="text-yellow-500 hover:text-white" />
                                <p className="text-gray-300 mt-2 text-sm">React</p>
                            </div>
                            <div className="flex flex-col items-center">
                                <SiHtml5 size={40} className="text-yellow-500 hover:text-white" />
                                <p className="text-gray-300 mt-2 text-sm">HTML5</p>
                            </div>
                            <div className="flex flex-col items-center">
                                <SiCss3 size={40} className="text-yellow-500 hover:text-white" />
                                <p className="text-gray-300 mt-2 text-sm">CSS3</p>
                            </div>
                            <div className="flex flex-col items-center">
                                <SiJavascript size={40} className="text-yellow-500 hover:text-white" />
                                <p className="text-gray-300 mt-2 text-sm">JavaScript</p>
                            </div>
                            <div className="flex flex-col items-center">
                                <SiGit size={40} className="text-yellow-500 hover:text-white" />
                                <p className="text-gray-300 mt-2 text-sm">GitLab</p>
                            </div>
                            <div className="flex flex-col items-center">
                                <SiWordpress size={40} className="text-yellow-500 hover:text-white" />
                                <p className="text-gray-300 mt-2 text-sm">Word Press</p>
                            </div>
                            <div className="flex flex-col items-center">
                                <SiAndroidstudio size={40} className="text-yellow-500 hover:text-white" />
                                <p className="text-gray-300 mt-2 text-sm">Android Studio</p>
                            </div>
                            <div className="flex flex-col items-center">
                                <SiXcode size={40} className="text-yellow-500 hover:text-white" />
                                <p className="text-gray-300 mt-2 text-sm">X Code</p>
                            </div>
                        </div>
                        <p className="text-gray-300 mt-4 font-bold">Audio, video y 3D:</p>
                        <div className="flex flex-wrap gap-6 mt-4 justify-start">
                            <div className="flex flex-col items-center">
                                <SiCinema4D size={40} className="text-yellow-500 hover:text-white" />
                                <p className="text-gray-300 mt-2 text-sm">Cinema 4D</p>
                            </div>
                            <div className="flex flex-col items-center">
                                <SiUnity size={40} className="text-yellow-500 hover:text-white" />
                                <p className="text-gray-300 mt-2 text-sm">Unity</p>
                            </div>
                            <div className="flex flex-col items-center">
                                <SiUnrealengine size={40} className="text-yellow-500 hover:text-white" />
                                <p className="text-gray-300 mt-2 text-sm">Unreal Engine</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-span-2 flex flex-col gap-10">
                    <div className="bg-gray-800 p-6 rounded-lg">
                        <div className='flex gap-2 items-center'>
                            <img className='w-16' src={icon_019} alt="image"></img>
                            <h2 className="text-yellow-500 text-xl font-bold">Perfil Profesional</h2>
                        </div>
                        <div className='flex gap-2'>
                            <div>
                                <div className='w-full h-[1px] my-4 bg-gradient-to-r from-purple-400 to-cyan-400'></div>
                                <p className="text-gray-300 mt-4">{professionalProfile}</p>
                            </div>
                            <div>
                                <img src={robot_011} alt="image"></img>
                            </div>
                        </div>
                    </div>


                    <div className='flex gap-5'>
                        <div className="w-full bg-gray-800 p-6 rounded-lg">
                            <div className='flex gap-2 items-center'>
                                <img className='w-16' src={icon_018} alt="image" ></img>                            
                                <h2 className="text-yellow-500 text-xl font-bold">Estudios</h2>
                            </div>
                            <div className='w-full h-[1px] my-4 bg-gradient-to-r from-purple-400 to-cyan-400'></div>
                            <ul className="text-gray-300 mt-4">
                                {studies.map((study, index) => (
                                    <li key={index} className="mb-4">
                                        <p className="text-yellow-400 font-bold">{study.degree}</p>
                                        <p>{study.institution} - {study.year}</p>
                                    </li>
                                ))}
                            </ul>
                        </div>

                        <div className="w-full bg-gray-800 p-6 rounded-lg">
                            <div className='flex gap-2 items-center'>
                                <img className='w-16' src={icon_017} alt="image" ></img>                                                        
                                <h2 className="text-yellow-500 text-xl font-bold">Idiomas</h2>
                            </div>
                            <div className='w-full h-[1px] my-4 bg-gradient-to-r from-purple-400 to-cyan-400'></div>
                            <ul className="text-gray-300 mt-4">
                                {languages.map((language, index) => (
                                    <li key={index} className="mb-4">
                                        <p className="text-yellow-400 font-bold">{language.language}</p>
                                        <p>Nivel: {language.level}</p>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>

                    <div className="bg-gray-800 p-6 rounded-lg">
                        <div className='flex gap-2 items-center mb-4'>
                            <img className='w-16' src={icon_015} alt="image" ></img>                            
                            <h2 className="w-4/12 text-yellow-500 text-xl font-bold ">Experiencia Laboral</h2>
                            <p className='w-8/12 text-gray-300'>
                                Creación de piezas gráficas impresas y digitales para campañas de marketing, creación de imagen institucional, desarrollo de sitios web aplicaciones y juegos para dispositivos móviles. 
                            </p>
                        </div>
                        <div className='w-full h-[1px] my-4 bg-gradient-to-r from-purple-400 to-cyan-400'></div>
                        <div className="hidden lg:grid lg:grid-cols-4 items-start justify-between relative gap-2">
                            {workExperience.slice(0, 4).map((job, index) => (
                                <div key={index} className="relative flex flex-col items-center group gap-5">
                                    <div className="text-white"><p className="text-[12px]">{job.period}</p></div>
                                    <div className="w-6 h-6 bg-yellow-500 rounded-full"></div>
                                    <div className="p-4 bg-gray-700 text-gray-300 rounded-lg gap-4">
                                        <p className="text-[12px] text-yellow-400 font-bold">{job.company}</p>
                                        <p className="text-[12px] text-yellow-400 font-bold">{job.role}</p>
                                        <p className="text-[12px] mt-2">{job.description}</p>
                                    </div>
                                </div>
                            ))}
                            <div className="absolute w-full h-1 bg-yellow-500 top-12 left-0"></div>
                        </div>
                        <div className="flex flex-col lg:hidden items-stretch justify-center relative gap-0">
                            {workExperience.slice(0, 4).map((job, index) => (
                                <div key={index} className="relative flex items-center gap-0">
                                    <div className="w-2/12 h-[130px] flex justify-center items-center  text-center text-white"><p className="text-[12px]">{job.period}</p></div>
                                    <div className="w-2/12 h-[130px] flex justify-center items-center relative">
                                        <div className="absolute w-1 h-full bg-yellow-500 top-0 left-[50%]"></div>
                                        <div className="w-6 h-6 bg-yellow-500 rounded-full"></div>
                                    </div>
                                    <div className="w-8/12 h-[130px] p-2">
                                        <div className='bg-gray-700 text-gray-300 rounded-lg p-4'>
                                            <p className="text-[12px] text-yellow-400 font-bold">{job.company}</p>
                                            <p className="text-[12px] text-yellow-400 font-bold">{job.role}</p>
                                            <p className="text-[12px] mt-2">{job.description}</p>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>

                    <div className="bg-gray-800 p-6 rounded-lg">
                        <div className='flex gap-2 items-center'>
                            <img className='w-16' src={icon_016} alt="image" ></img>                                                    
                            <h2 className="w-3/12 text-yellow-500 text-xl font-bold">Portafolio</h2>
                            <p className='w-9/12 text-gray-300'>
                                Sitios web personalizados según los requerimientos del usuario.
                            </p>
                        </div>
                        <div className='w-full h-[1px] my-4 bg-gradient-to-r from-purple-400 to-cyan-400'></div>
                        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 mt-6">
                            {portfolioProjects.map((project, index) => (
                                <div key={index} className="bg-gray-700 rounded-lg overflow-hidden">
                                    <img src={project.image} alt={project.title} className="w-full h-40 object-cover" />
                                    <div className="p-4">
                                        <h3 className="text-yellow-400 font-bold text-lg">{project.title}</h3>
                                        <p className="text-gray-300 mt-2">{project.description}</p>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default Profile;

