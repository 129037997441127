import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet-async";
import Header from "../organisms/Header";
import Carousel from "../molecules/carousel/Carousel";
import Modal from "../molecules/modals/Modal";
import ContactAndCartModal from "../molecules/modals/ContactAndCartModal";
import UIButton from "../atoms/UIButton";
import slide_001 from "../../assets/images/games/games_001.webp";
import slide_002 from "../../assets/images/games/games_002.webp";
import slide_003 from "../../assets/images/games/games_003.webp";
import games_004 from "../../assets/images/games/games_004.webp";
import games_005 from "../../assets/images/games/games_005.webp";
import games_006 from "../../assets/images/games/games_006.webp";
import icon_001 from "../../assets/images/sliders/icon-seguridad001.webp";
import icon_002 from "../../assets/images/sliders/icon-seguridad002.webp";
import icon_003 from "../../assets/images/sliders/icon-seguridad003.webp";
import icon_004 from "../../assets/images/sliders/icon-seguridad004.webp";
import icon_005 from "../../assets/images/sliders/icon-seguridad005.webp";
import icon_006 from "../../assets/images/sliders/icon-seguridad006.webp";
import icon_007 from "../../assets/images/sliders/icon-seguridad007.webp";
import icon_008 from "../../assets/images/sliders/icon-seguridad008.webp";
import icon_009 from "../../assets/images/sliders/icon-seguridad009.webp";
import icon_010 from "../../assets/images/sliders/icon-seguridad010.webp";
import icon_011 from "../../assets/images/sliders/icon-seguridad011.webp";
import icon_012 from "../../assets/images/sliders/icon-seguridad012.webp";
import icon_013 from "../../assets/images/sliders/icon-seguridad013.webp";
import icon_014 from "../../assets/images/sliders/icon-seguridad014.webp";
import icon_015 from "../../assets/images/sliders/icon-seguridad015.webp";
import icon_016 from "../../assets/images/sliders/icon-seguridad016.webp";
import icon_0009 from "../../assets/images/icons/icon_009.webp";
import icon_0010 from "../../assets/images/icons/icon_010.webp";
import icon_0011 from "../../assets/images/icons/icon_011.webp";
import icon_0012 from "../../assets/images/icons/icon_012.webp";
import icon_0013 from "../../assets/images/icons/icon_013.webp";
import icon_0014 from "../../assets/images/icons/icon_014.webp";
import robot_008 from "../../assets/images/robots/robot_008.png";
import CarouselBlog from "../molecules/carousel/CarouselBlog";
import SocialContacts from "../organisms/SocialContacts";
import robot from "../../assets/images/robots/platform.png";

const Games = () => {

    const { t } = useTranslation();

    const navigate = useNavigate();
    const goContact = () => {
        navigate("/contact");
    };

    const slides = [
        { image: slide_001, text: <span>Experiencias <span className="text-yellow-500">únicas y divertidas</span> con tu marca </span> },
        { image: slide_002, text: <span>Premia a tus <span className="text-yellow-500">mejores usuarios</span> con juegos que no olvidarán</span> },
        { image: slide_003, text: <span>Múltiples <span className="text-yellow-500">plataformas</span> para diferentes edades y gustos</span> },
    ];
    const [selectedPlan, setSelectedPlan] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const openModalWithPlan = (plan) => {
        console.log("Plan seleccionado:", plan); // Para depuración
        setSelectedPlan(plan);
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
        setSelectedPlan(null);
    };

    const cards = [
        {
            id: "platform_games",
            name: "Juegos de plataforma",
            description: "Juegos 2D o 3D donde se avanza por un escenario esquivando obstáculos y tomando recompensas hasta llegar al final del nivel.",
            image: games_004,
            price: 1000000,
            descriptionPlan: "Una aplicación formato .apk para subir a la playstore o a tu sitio web para compartir con tus usuarios.",
            extras: [
                { id: "logo_design", name: "Diseño de Logo Profesional", price: 300000 },
                { id: "branding_manual", name: "Manual de Imagen Corporativa.", price: 500000 },
                { id: "brochure", name: "Brochure o menú: (1) Un Archivo .pdf para impresión y (1) en resolución para pantalla de tus productos o servicios.", price: 400000 },
                { id: "video", name: "Video explicativo para redes sociales de 30 a 40 segundos", price: 400000 },
            ],
        },
        {
            id: "personalized_games",
            name: "Juegos personalizados",
            description: "Juegos de cartas o de tablero (como poker o escalera) donde intervienen turnos para diferentes jugadores.",
            image: games_005,
            price: 1000000,
            descriptionPlan: "Una aplicación formato .apk para subir a la playstore o a tu sitio web para compartir con tus usuarios.",
            extras: [
                { id: "logo_design", name: "Diseño de Logo Profesional", price: 300000 },
                { id: "branding_manual", name: "Manual de Imagen Corporativa.", price: 500000 },
                { id: "brochure", name: "Brochure o menú: (1) Un Archivo .pdf para impresión y (1) en resolución para pantalla de tus productos o servicios.", price: 400000 },
                { id: "video", name: "Video explicativo para redes sociales de 30 a 40 segundos", price: 400000 },
            ],
        },
        {
            id: "logic_games",
            name: "Juegos de lógica",
            description: "Juegos donde se pone a prueba habilidades como matemáticas, idiomas que pueden involucrar a uno varios jugadores.",
            image: games_006,
            price: 1000000,
            descriptionPlan: "Una aplicación formato .apk para subir a la playstore o a tu sitio web para compartir con tus usuarios",
            extras: [
                { id: "logo_design", name: "Diseño de Logo Profesional", price: 300000 },
                { id: "branding_manual", name: "Manual de Imagen Corporativa.", price: 500000 },
                { id: "brochure", name: "Brochure o menú: (1) Un Archivo .pdf para impresión y (1) en resolución para pantalla de tus productos o servicios.", price: 400000 },
                { id: "video", name: "Video explicativo para redes sociales de 30 a 40 segundos", price: 400000 },
            ],
        },
    ];

    return (
        <React.Fragment>
            <Helmet>
                <title>{t("GamesTitle")} | Enlace Web Design</title>
                <meta name="description" content={t("GamesDescription")} />
                <meta name="keywords" content={t("GamesKeywords")} />
            </Helmet>

            <Header />
            <Modal isOpen={isModalOpen} onClose={closeModal} className="!bg-[#091433] border border-yellow-500 p-5">
                <ContactAndCartModal plan={selectedPlan} />
            </Modal>
            <Carousel
                slides={slides}
            />
            <div className="w-8/10 m-auto">
                 <div className="pt-40 pb-20 px-10">
                     <div className="w-full xl:w-8/12 xl:max-w-[1024px] lg:h-[250px] m-auto flex flex-col md:flex-row gap-4 p-6 lg:p-10 items-center bg-gradient-cards from-brand-gray via-brand-gray to-brand-dark-gray border border-yellow-500 rounded-xl">
                         <div className="text-center w-full md:w-1/2 order-2 md:order-1">
                             <p className="text-yellow-500 font-bold text-4xl mb-5">
                                 No hay límite para lo que podemos crear
                             </p>
                             <p className="text-white font-normal text-xl">
                                 Ofrece a tus clientes nuevas interacciones y formas de estar en contacto.
                             </p>
                         </div>
                         <div className="relative w-full md:w-1/2 h-[250px] lg:h-[160px] order-1 md:order-2">
                             <img className="absolute lg:left-1/2 lg:-translate-x-1/2 bottom-[-20px] lg:bottom-[-80px] w-[100%] md:w-[350px] lg:w-[480px] max-w-none m-auto" src={robot} alt="robot"/>
                         </div>
                     </div>
                 </div>
             </div>
            <div className="w-10/12 max-w-[1100px] m-auto grid grid-cols-1 lg:grid-cols-3 gap-5 lg:gap-20 py-20 p-5">
                {cards.map((card) => (
                    <div key={card.id} className="w-full flex flex-col gap-5 items-center text-center">
                        <div className="bg-white rounded-lg w-full overflow-hidden">
                            <img className="w-full h-auto" src={card.image} alt={card.name} />
                        </div>
                        <p className="text-yellow-500 font-bold text-2xl">{card.name}</p>
                        <p className="text-white font-normal text-xl">{card.description}</p>
                        <p className="text-yellow-500 font-bold text-lg">Desde: ${card.price.toLocaleString("es-CO")} COP</p>
                        <UIButton primary action={() => openModalWithPlan(card)} children={"Ver detalles"} />
                    </div>
                ))}
            </div>
            <CarouselBlog />
            <SocialContacts />
        </React.Fragment>
    );
};

export default Games;
