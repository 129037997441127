import React, { useState, Suspense, lazy } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet-async";
import Header from "../organisms/Header";
import Carousel from "../molecules/carousel/Carousel";
import Modal from "../molecules/modals/Modal";
import ContactAndCartModal from "../molecules/modals/ContactAndCartModal";
import UIButton from "../atoms/UIButton";
import slide_001 from "../../assets/images/about/about_001.webp";
import slide_002 from "../../assets/images/about/about_002.webp";
import slide_003 from "../../assets/images/about/about_003.webp";
import icon_0009 from "../../assets/images/icons/icon_009.webp";
import icon_0010 from "../../assets/images/icons/icon_010.webp";
import icon_0011 from "../../assets/images/icons/icon_011.webp";
import icon_0012 from "../../assets/images/icons/icon_012.webp";
import icon_0013 from "../../assets/images/icons/icon_013.webp";
import icon_0014 from "../../assets/images/icons/icon_014.webp";
import robot_008 from "../../assets/images/robots/robot_008.png";
import Loader from "../molecules/loader/Loader";
import UxDesign from "../molecules/uxDesign/UxDesign";

const SocialContacts = lazy(() => import("../organisms/SocialContacts"));
const CarouselBlog = lazy(() => import("../molecules/carousel/CarouselBlog"));

const AboutUs = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const goContact = () => {
        navigate("/contact");
    };

    const slides = [
        { 
            image: slide_001, 
            text: <span>Te ayudamos a cumplir tus <span className="text-yellow-500">objetivos</span> y a seguir creciendo</span>, 
            lead: "Cree en tus metas y trabaja con los mejores",
        },
        { 
            image: slide_002, 
            text: <span><span className="text-yellow-500">Soporte en línea</span> para tus proyectos</span>, 
            lead: <span>Siempre disponibles para ayudarte<br /> <a className="text-yellow-500" href="mailto:contacto@enlacewebdesign.com" >contacto@enlacewebdesign.com</a></span>,
        },
        {   
            image: slide_003, 
            text: <span>Seguridad y código desarrollado con las <span className="text-yellow-500">mejores prácticas</span></span>,
            lead: "Confianza, calidad y efectividad en nuestros desarrollos",
        },
    ];

    const [selectedPlan, setSelectedPlan] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const openModalWithPlan = (plan) => {
        console.log("Plan seleccionado:", plan); // Para depuración
        setSelectedPlan(plan);
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
        setSelectedPlan(null);
    };

    const cards = [
        {
            id: "corporate_design",
            name: "Diseño de imagen corporativa",
            description: "Te ayudamos a crear una imagen visual llamativa que integre el concepto y la identidad de tu empresa.",
            image: icon_0009,
            price: 500000,
            descriptionPlan: "El logo de tu empresa en formato editable y para impresión, (1) archivo pdf con tipografías, colores y aplicaciones en formatos como membrete y tarjeta de presentación.",
            extras: [
                { id: "consultory", name: "Consultoría para saber el estado de tu sitio web.", price: 50000 },
                { id: "brochure", name: "Brochure o menú: (1) Un Archivo .pdf para impresión y (1) en resolución para pantalla de tus productos o servicios.", price: 400000 },
            ],
        },
        {
            id: "marketing_campaigns",
            name: "Campañas de Marketing",
            description: "Diseñamos estrategias de marketing personalizadas para aumentar tu visibilidad y captar nuevos clientes de manera efectiva.",
            image: icon_0010,
            price: 200000,
            descriptionPlan: "(1) Un diseño adaptado a las redes sociales de tu preferencia, (1) video de la campaña (adaptado para tus redes sociales).",
            extras: [
                { id: "logo_design", name: "Diseño de Logo Profesional", price: 300000 },
                { id: "branding_manual", name: "Manual de Imagen Corporativa.", price: 500000 },
                { id: "brochure", name: "Brochure o menú: (1) Un Archivo .pdf para impresión y (1) en resolución para pantalla de tus productos o servicios.", price: 400000 },
            ],
        },
        {
            id: "websites",
            name: "Sitios Web Responsive",
            description: "Creamos sitios web responsivos, funcionales y optimizados para ofrecer una experiencia de usuario excepcional.",
            image: icon_0011,
            price: 1200000,
            descriptionPlan: "(1) Un sitio web con (4) cuatro páginas como Inicio, Sobre Nosotros, Servicios, Productos y Contacto.",
            extras: [
                { id: "logo_design", name: "Diseño de Logo Profesional", price: 300000 },
                { id: "branding_manual", name: "Manual de Imagen Corporativa.", price: 500000 },
                { id: "brochure", name: "Brochure o menú: (1) Un Archivo .pdf para impresión y (1) en resolución para pantalla de tus productos o servicios.", price: 400000 },
            ],
        },
        {
            id: "applications",
            name: "Aplicaciones",
            description: "Desarrollamos aplicaciones móviles y de escritorio adaptadas a las necesidades específicas de tu negocio.",
            image: icon_0012,
            price: 1500000,
            descriptionPlan: "App para android y iOS desarrollada con React Native, (5) páginas: (1) vista de login, (1) para home out y (1) home in, (1) página de servicios/productos y vista de contacto.",
            extras: [                
                { id: "logo_design", name: "Diseño de Logo Profesional", price: 300000 },
                { id: "branding_manual", name: "Manual de Imagen Corporativa.", price: 500000 },
                { id: "brochure", name: "Brochure o menú: (1) Un Archivo .pdf para impresión y (1) en resolución para pantalla de tus productos o servicios.", price: 400000 },
            ],
        },
        {
            id: "games",
            name: "Juegos",
            description: "Creamos juegos interactivos y entretenidos con gráficos de alta calidad y mecánicas innovadoras.",
            image: icon_0013,
            price: 2000000,
            descriptionPlan: "Diseñamos y desarrollamos (1) archivo .apk con Unity, partimos desde (3) Tres escenas: menú principal, escena de juego y escena final.",
            extras: [
                { id: "logo_design", name: "Diseño de Logo Profesional", price: 300000 },
                { id: "branding_manual", name: "Manual de Imagen Corporativa.", price: 500000 },
                { id: "brochure", name: "Brochure o menú: (1) Un Archivo .pdf para impresión y (1) en resolución para pantalla de tus productos o servicios.", price: 400000 },                
            ],
        },
        {
            id: "consult",
            name: "Consultoría",
            description: "Consultoría para saber el estado de tu sitio web.",
            image: icon_0014,
            price: 50000,
            descriptionPlan: "Realizamos diferentes pruebas para saber el estado de tu sitio web y te daremos un diagnóstico para posterior administración o actualización.",
            extras: [
                { id: "logo_design", name: "Diseño de Logo Profesional", price: 300000 },
                { id: "branding_manual", name: "Manual de Imagen Corporativa.", price: 500000 },
                { id: "brochure", name: "Brochure o menú: (1) Un Archivo .pdf para impresión y (1) en resolución para pantalla de tus productos o servicios.", price: 400000 },                
            ],
        },
    ];

    return (
        <React.Fragment>
            {/* Helmet para SEO */}
            <Helmet>
                <title>{t("AboutUsWelcome")} | Enlace Web Design</title>
                <meta name="description" content={t("AboutUsDescription")} />
                <meta
                    name="keywords"
                    content="Enlace Web Design, diseño web profesional, desarrollo de aplicaciones web, diseño de sitios personalizados, experiencia de usuario, desarrollo web a medida, creación de páginas web, agencia de diseño web, diseño responsive, optimización de sitios web, equipo de diseño web, soluciones web innovadoras"
                />
            </Helmet>

            <Header />
            <Modal isOpen={isModalOpen} onClose={closeModal} className="!bg-[#091433] border border-yellow-500 p-5">
                <ContactAndCartModal plan={selectedPlan} />
            </Modal>
            <Carousel
                slides={slides}
            />
            <div className="flex flex-col items-center m-auto gap-5 w-full lg:w-10/12 xl:max-w-[1440px] py-10 px-5 lg:px-40">
                <p className="text-yellow-500 font-bold text-4xl text-center">
                    {t("AboutUsWelcome")}
                </p>
                <p className="text-white font-normal text-2xl text-center w-full m-auto">
                    {t("AboutUsDescription")}
                </p>
            </div>
            <div className="w-10/12 max-w-[1100px] m-auto grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5 lg:gap-20 py-20 p-5">
                {cards.map((card) => (
                    <div key={card.id} className="w-full flex flex-col gap-5 items-center text-center">
                        <img className="w-[120px]" src={card.image} alt={card.name} />
                        <p className="text-yellow-500 font-bold text-2xl">{card.name}</p>
                        <p className="text-white font-normal text-xl">{card.description}</p>
                        <p className="text-yellow-500 font-bold text-lg">Desde: ${card.price.toLocaleString("es-CO")} COP</p>
                        <UIButton primary action={() => openModalWithPlan(card)} children={"Ver detalles"} />
                    </div>
                ))}
            </div>
            <div className="py-40 px-5">
                <div className="w-full lg:w-10/12 xl:max-w-[1024px] h-[400px] lg:h-[340px] m-auto flex flex-col-reverse lg:flex-row gap-4 p-10 items-center bg-gradient-cards from-brand-gray via-brand-gray to-brand-dark-gray border border-yellow-500 rounded-xl">
                    <div className="text-center w-full lg:w-1/2">
                        <p className="text-yellow-500 font-bold text-xl lg:text-4xl mb-6">
                            {t("AboutUsCommitment")}
                        </p>
                        <p className="text-white font-normal text-xl">
                            {t("AboutUsCommitmentDescription")}
                        </p>
                        <div className="py-2">
                            <UIButton
                                primary
                                scrollToTop
                                children={t("AboutUsContactButton")}
                                action={goContact}
                            />
                        </div>
                    </div>
                    <div className="relative w-full lg:w-1/2 h-full">
                        <img className="lg:absolute lg:left-1/2 lg:-translate-x-1/2 lg:bottom-[-80px] w-[80%] m-auto lg:w-[550px] max-w-none" src={robot_008} alt="robot" />
                    </div>
                </div>
            </div>
            <Suspense fallback={<Loader />}>
                <UxDesign />
                <CarouselBlog />
                <SocialContacts />            
            </Suspense>
        </React.Fragment>
    );
};

export default AboutUs;
