import React from "react";
import Badge from "../../atoms/Badge";
import UIButton from "../../atoms/UIButton";

const ClientDescription = ({
    title,
    description1,
    description2,
    buttonText,
    buttonUrl,
    logoSrc,
    siteImageSrc,
    badges = [],
}) => {
    return (
        <div>
            <div className="flex flex-col lg:flex-row w-full lg:w-9/12  m-auto py-10">
                <div className="flex flex-col w-full lg:w-5/12 justify-start items-center gap-5">
                    <img className="w-full" src={logoSrc} alt="Client Logo" />
                    <p className="text-white text-center">{description1}</p>
                    <p className="text-white text-center">{description2}</p>
                    <UIButton
                        className="py-2"
                        primary
                        url={buttonUrl}
                    >
                    {buttonText}
                </UIButton>
                </div>
                <div className="w-full lg:w-7/12">
                    <p className="text-white font-bold text-5xl text-center w-full m-0">
                        {title}
                    </p>
                    <div className="flex justify-center gap-2 p-1 my-2 md:p-5">
                        {badges.map((badge, index) => (
                            <Badge key={index} text={badge.text} variant={badge.variant} />
                        ))}
                    </div>
                    <div className="p-5">
                        <img className="w-full m-auto" src={siteImageSrc} alt="Client Site" />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ClientDescription;
