import React, { useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet-async";
import blogData from "../../data/blogData.json";
import robot from "../../assets/images/robots/robot_010.png";
import Header from "../organisms/Header";
import SocialContacts from "../organisms/SocialContacts";
import UIButton from "../atoms/UIButton";
import iconSave from "../../assets/images/icons/icon_download.webp";

const BlogArticle = () => {
    const { t } = useTranslation();
    const { slug } = useParams();
    const navigate = useNavigate();

    const [searchQuery, setSearchQuery] = useState("");

    // Encuentra el artículo que coincide con el slug
    const articleData = blogData.find((article) => article.slug === slug);

    if (!articleData) {
        navigate("/blog");
        return null;
    }

    const goMenuBlog = () => {
        navigate("/blog");
    };

    const { title, date, lead, fullDescription, image, assets } = articleData;

    return (
        <React.Fragment>
            {/* Helmet para SEO */}
            <Helmet>
                <title>{articleData.title} | Enlace Web Design</title>
                <meta name="description" content={articleData.shortDescription} />
                <meta name="keywords" content={`Blog, ${articleData.title}, diseño web`} />
            </Helmet>
            <Header />
            <div className="w-full">
                <div className="w-full min-h-[800px] m-auto py-10 lg:py-24">
                    <div className="pt-20 pb-20 px-10">
                        <div className="relative w-full xl:w-8/12 lg:max-w-[850px] lg:h-[150px] m-auto flex flex-col-reverse md:flex-row gap-4 p-6 lg:p-10 items-center bg-gradient-cards from-brand-gray via-brand-gray to-brand-dark-gray border border-yellow-500 rounded-xl">
                            <div className="absolute -top-8 left-5 z-40">
                                <UIButton
                                    scrollToTop
                                    className={'text-yellow-500'}
                                    children={`< ${t("BlogArticleBackMenu")}`}
                                    action={goMenuBlog}
                                />
                            </div>
                            <div className="text-center w-full md:w-7/12 lg:w-9/12">
                                <p className="text-white font-normal text-xl">{t("BlogArticleTechBlog")}</p>
                                <p className="text-yellow-500 font-bold text-4xl">{t("BlogArticleStayInformed")}</p>
                                <p className="text-white font-normal text-xl">{t("BlogArticleTopics")}</p>
                            </div>
                            <div className="relative w-full md:w-5/12 lg:w-3/12 sm:h-[130px] lg:h-[160px] z-20">
                                <img
                                    className="absolute -right-10 lg:left-1/2 lg:-translate-x-1/2 bottom-[-20px] sm:bottom-[-100px] lg:bottom-[-50px] w-[140px] sm:w-[350px] md:w-[350px] lg:w-[300px] max-w-none m-auto"
                                    src={robot}
                                    alt="Robot"
                                />
                            </div>
                        </div>
                    </div>
                    <div className="w-full flex flex-col lg:flex-row gap-5 px-5">
                        <div className="w-full lg:w-10/12 p-5 lg:px-20">
                            <h1 className="text-yellow-500 mb-5 font-bold text-xl lg:text-4xl text-center">{title}</h1>
                            <p className="text-gray-300 text-xs lg:text-sm mb-4">{t("BlogArticlePublished")} {date}</p>
                            <p className="text-gray-300 text-base italic mb-6">{lead}</p>
                            <div className="px-5">
                                <img className="w-full mb-6 rounded-lg" src={image} alt={title} />
                            </div>
                            <p className="text-white text-lg">{fullDescription}</p>
                        </div>
                        <div className="w-full lg:w-2/12 bg-gradient-cards from-brand-gray via-brand-gray to-brand-dark-gray p-5 border border-yellow-500 rounded-xl">
                            <div className="mb-5">
                                <input
                                    type="text"
                                    placeholder={t("BlogArticleSearchPlaceholder")}
                                    className="w-full p-2 rounded bg-gray-800 text-white border border-gray-600"
                                    value={searchQuery}
                                    onChange={(e) => setSearchQuery(e.target.value)}
                                />
                            </div>
                            <div className="mb-5">
                                <h3 className="text-yellow-500 font-bold text-lg mb-3">{t("BlogArticleRelated")}</h3>
                                <ul className="space-y-3">
                                    {blogData
                                        .filter((article) =>
                                            article.title.toLowerCase().includes(searchQuery.toLowerCase()) &&
                                            article.slug !== slug
                                        )
                                        .map((article) => (
                                            <li key={article.id}>
                                                <button
                                                    onClick={() => navigate(`/blog/${article.slug}`)}
                                                    className="w-full text-white hover:underline text-sm border-b border-b-slate-600 p-4 hover:bg-slate-500"
                                                >
                                                    {article.title}
                                                </button>
                                            </li>
                                        ))}
                                </ul>
                            </div>
                            {assets && assets.length > 0 && (
                                <div className="mt-10">
                                    <h3 className="text-yellow-500 font-bold text-lg mb-3">{t("BlogArticleDownloadableAssets")}</h3>
                                    <ul className="space-y-3">
                                        {assets.map((asset, index) => (
                                            <li key={index}>
                                                <a href={asset.url} target="_blank" rel="noopener noreferrer" className="text-white hover:underline text-sm">
                                                    <div className="flex gap-4 items-center border-b border-b-slate-600 p-4 hover:bg-slate-500">
                                                        <img className="w-6 h-6" src={iconSave} alt="Icono" />
                                                        {asset.name}
                                                    </div>
                                                </a>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <SocialContacts />
        </React.Fragment>
    );
};

export default BlogArticle;
