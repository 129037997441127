import React from "react";

const VideoCard = ({ image, title, description, date, onActionClick }) => {
  const handleCardClick = () => {
    window.scrollTo({ top: 0, behavior: "smooth" }); // Scroll al top
    onActionClick(); // Navegar al artículo correspondiente
  };

  return (
    <div
      className="flex flex-col cursor-pointer border border-yellow-500 rounded-xl overflow-hidden hover:scale-105 transition-transform duration-300 h-full"
      onClick={handleCardClick}
    >
      {/* Imagen */}
      <div className="rounded-t-xl overflow-hidden">
        <img className="w-full h-[200px] object-cover" src={image} alt={title} />
      </div>

      {/* Contenido */}
      <div className="flex flex-col flex-grow bg-gradient-cards from-brand-gray via-brand-gray to-brand-dark-gray p-5">
        <p className="text-yellow-500 text-xl font-bold mb-2">{title}</p>
        <p className="text-white text-base mb-2">{description}</p>
        <p className="text-gray-400 text-sm mt-auto">{date}</p> {/* Fecha pegada abajo */}
      </div>
    </div>
  );
};

export default VideoCard;
