
import React from "react";

const Badge = ({ text, variant = "primary", className, icon }) => {
    const variants = {
        primary: "bg-blue-500 text-white",
        secondary: "bg-gray-500 text-white",
        success: "bg-green-500 text-white",
        danger: "bg-red-500 text-white",
        warning: "bg-yellow-500 text-black",
        info: "bg-cyan-500 text-white",
        light: "bg-gray-200 text-gray-800",
        dark: "bg-gray-800 text-white",
        webSite: "bg-[#008CA8] text-white",
        LandingPage: "bg-[#FFAF26] text-black",
        Responsive: "bg-[#FB26FF] text-white",
        Ecommerce: "bg-[#FB26FF] text-white",
        App: "bg-[#FB26FF] text-white",
    };
    return (
        <span
        className={`inline-flex items-center gap-2 px-2 py-1 rounded-full text-[10px] md:text-xs font-semibold ${variants[variant]} ${className}`}
    >
        {icon && <span>{icon}</span>}
        {text}
    </span>
    )
}
export default Badge;