import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet-async";
import Header from "../organisms/Header";
import Carousel from "../molecules/carousel/Carousel";
import slide_001 from "../../assets/images/apps/apps_001.webp";
import slide_002 from "../../assets/images/apps/apps_002.webp";
import slide_003 from "../../assets/images/apps/apps_003.webp";
import UIButton from "../atoms/UIButton";
import icon_005 from "../../assets/images/icons/icon_005_1x.webp";
import icon_006 from "../../assets/images/icons/icon_006_1x.webp";
import icon_007 from "../../assets/images/icons/icon_007_1x.webp";
import icon_010 from "../../assets/images/icons/icon_010.webp";
import ClientDescription from "../molecules/clientDescription/ClientDescription";
import demo_explorer_001 from "../../assets/images/demo-explorer/images/brand/brand_positive.webp";
import demo_explorer_002 from "../../assets/images/demo-explorer/images/banner-discover/img_mobile.webp";
import SocialContacts from "../organisms/SocialContacts";
import CarouselBlog from "../molecules/carousel/CarouselBlog";
import Modal from "../molecules/modals/Modal";
import ContactAndCartModal from "../molecules/modals/ContactAndCartModal";
import cohete from "../../assets/images/robots/robot_012.png";

const Apps =()=>{

    const { t } = useTranslation();

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedPlan, setSelectedPlan] = useState({
        id: "custom_app",
        name: t("AppsBrochureButtonPricing"),
        descriptionPlan: "Un archivo .apk de React Native listo para subir a app y play store.",
        price: 1500000,
        extras: [
            { id: "feature1", name: t("AppsTitleCommerce"), price: 800000 },
            { id: "feature2", name: t("AppsTitleSocial"), price: 800000 },
            { id: "feature3", name: t("AppsTitleEntertaiment"), price: 600000 },
            { id: "feature4", name: t("AppsTitleInformation"), price: 600000 },
        ],
    });

    const openModal = () => setIsModalOpen(true);
    const closeModal = () => setIsModalOpen(false);

    const slides = [
        { 
            image: slide_001, 
            text: <span>Servicios para tus clientes en su <span className="text-yellow-500">dispositivo móvil</span></span>,
            lead: "Tu cliente siempre te tendrá cerca",
        },
        { 
            image: slide_002, 
            text: <span>Interacción directa y la <span className="text-yellow-500">mejor experiencia</span> para tu cliente</span>,
            lead: "Soluciones que conectan tus cliente con tu negocio",
        },
        { 
            image: slide_003, 
            text: <span><span className="text-yellow-500">Interfaces únicas</span> con diseños personalizados</span>,
            lead: "Creamos diseños que reflejan la identidad de tu marca",
        },
    ];

    return (
        <React.Fragment>
            <Helmet>
                <title>Aplicaciones | Enlace Web Design</title>
                <meta
                    name="description"
                    content={t("AppsDescription")}
                />
                <meta
                    name="keywords"
                    content={t("AppsKeywords")}
                />
            </Helmet>

            <Header />
            <Carousel
                slides={slides}
            />
            <Modal isOpen={isModalOpen} onClose={closeModal} className="!bg-[#091433] border border-yellow-500 p-5">
                <ContactAndCartModal plan={selectedPlan} />
            </Modal>
            <div className="w-full">
                <div className="w-10/12 m-auto flex flex-col gap-5 items-center">
                    <div className="py-10 flex flex-col items-center gap-5 text-center">
                        <p className="text-white font-normal text-xl">
                            {t("AppsDemosTitle")}
                        </p>
                        <p className="text-yellow-500 font-bold text-4xl ">
                            {t("AppsIdeasTitle")}
                        </p>
                        <div className="flex flex-col lg:flex-row gap-5">
                            <UIButton
                                className={''}
                                secondary
                                children={t("AppsBrochureButtonBrochure")}
                                url={''}
                            />
                            <UIButton
                                className={''}
                                secondary
                                children={t("AppsBrochureButtonDemo")}
                                url={'https://enlacewebdesign.com/portafolio/brochure_enlace-web-design-es-2024.pdf'}
                            />
                        </div>
                    </div>
                    <div className="w-full lg:max-w-[900px] h-[350px] lg:h-[340px] my-24 mx-auto flex flex-col-reverse lg:flex-row gap-4 p-2 lg:p-10 items-center bg-gradient-cards from-brand-gray via-brand-gray to-brand-dark-gray border border-yellow-500 rounded-xl">
                        <div className="text-center w-full lg:w-1/2">
                            <p className="text-yellow-500 font-bold text-xl lg:text-4xl mb-6">
                                {t("AppsTitlePricing")}
                            </p>
                            <p className="text-white font-normal text-xl">
                                {t("AppsTextPricing")}
                            </p>
                            <div className="py-2">
                            <UIButton
                                className={'w-full lg:w-1/2'}
                                primary
                                children={t("AppsBrochureButtonPricing")}
                                action={openModal}
                            />
                            </div>
                        </div>
                        <div className="relative w-full lg:w-1/2 h-full">
                            <img className="lg:absolute lg:left-1/2 lg:-translate-x-1/2 lg:bottom-[-120px] w-[80%] m-auto lg:w-[550px] max-w-none" src={cohete} alt="cohete"/>
                        </div>
                    </div>
                    <div className="grid grid-cols-1 lg:grid-cols-2 gap-20 lg:px-20  text-center lg:text-left">
                        <div className="flex flex-col lg:flex-row gap-5 items-center lg:items-start">
                            <div className="w-4/12">
                                <img src={icon_007} alt="image" ></img>
                            </div>
                            <div className="w-full lg:w-8/12 flex flex-col">
                                <p className="text-white font-bold text-xl lg:text-4xl">
                                    {t("AppsTitleCommerce")}
                                </p>
                                <p className="text-white font-normal text-base lg:text-xl">
                                    {t("AppsTextCommerce")}                                    
                                </p>
                            </div>
                        </div>
                        <div className="flex flex-col lg:flex-row gap-5 items-center lg:items-start">
                            <div className="w-4/12">
                                <img src={icon_010} alt="image" ></img>
                            </div>
                            <div className="w-full lg:w-8/12 flex flex-col">
                                <p className="text-white font-bold text-xl lg:text-4xl">
                                    {t("AppsTitleSocial")}                
                                </p>
                                <p className="text-white font-normal text-base lg:text-xl">
                                    {t("AppsTextSocial")}                                    
                                </p>
                            </div>
                        </div>
                        <div className="flex flex-col lg:flex-row gap-5 items-center lg:items-start">
                            <div className="w-4/12">
                                <img src={icon_006} alt="image" ></img>
                            </div>
                            <div className="w-full lg:w-8/12 flex flex-col">
                                <p className="text-white font-bold text-xl lg:text-4xl">
                                    {t("AppsTitleEntertaiment")}
                                </p>
                                <p className="text-white font-normal text-base lg:text-xl">
                                    {t("AppsTextEntertaiment")}                                    
                                </p>
                            </div>
                        </div>
                        <div className="flex flex-col lg:flex-row gap-5 items-center lg:items-start">
                            <div className="w-4/12">
                                <img src={icon_005} alt="image" ></img>
                            </div>
                            <div className="w-full lg:w-8/12 flex flex-col">
                                <p className="text-white font-bold text-xl lg:text-4xl">
                                    {t("AppsTitleInformation")}
                                </p>
                                <p className="text-white font-normal text-base lg:text-xl">
                                    {t("AppsTextInformation")}                                    
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="w-full text-center mt-24 lg:mt-40">
                        <p className="text-white font-normal text-xl">
                            {t("AppsTitleDemos")}                            
                        </p>
                        <p className="text-yellow-500 font-bold text-4xl">
                            {t("AppsTextDemos")}                            
                        </p>
                    </div>
                    <ClientDescription
                        title='Demo explorer'
                        description1={t("AppsClientDescription1")}
                        description2=""
                        buttonText={t("AppsClientDescButton")}
                        buttonUrl="https://enlacewebdesign.com/demo-explorer/index.html"
                        logoSrc={demo_explorer_001}
                        siteImageSrc={demo_explorer_002}
                        badges={[
                            { text: "Sitio Web", variant: "primary" },
                            { text: "Landing page", variant: "LandingPage" },
                            { text: "Responsive", variant: "Responsive" },
                        ]}
                    />
                </div>
                <CarouselBlog />
                <SocialContacts />
            </div>
        </React.Fragment>
    )
}
export default Apps;
