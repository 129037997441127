import React, { Suspense, lazy } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import icon_001 from "../../../assets/images/sliders/icon-seguridad001.webp";
import icon_002 from "../../../assets/images/sliders/icon-seguridad002.webp";
import icon_003 from "../../../assets/images/sliders/icon-seguridad003.webp";
import icon_004 from "../../../assets/images/sliders/icon-seguridad004.webp";
import icon_005 from "../../../assets/images/sliders/icon-seguridad005.webp";
import icon_006 from "../../../assets/images/sliders/icon-seguridad006.webp";
import icon_007 from "../../../assets/images/sliders/icon-seguridad007.webp";
import icon_008 from "../../../assets/images/sliders/icon-seguridad008.webp";
import icon_009 from "../../../assets/images/sliders/icon-seguridad009.webp";
import icon_010 from "../../../assets/images/sliders/icon-seguridad010.webp";
import icon_011 from "../../../assets/images/sliders/icon-seguridad011.webp";
import icon_012 from "../../../assets/images/sliders/icon-seguridad012.webp";
import icon_013 from "../../../assets/images/sliders/icon-seguridad013.webp";
import icon_014 from "../../../assets/images/sliders/icon-seguridad014.webp";
import icon_015 from "../../../assets/images/sliders/icon-seguridad015.webp";
import icon_016 from "../../../assets/images/sliders/icon-seguridad016.webp";
import Loader from "../loader/Loader";
import UIButton from "../../atoms/UIButton";

const UxDesign = () => {
    const { t } = useTranslation();
     const navigate = useNavigate();

    const goWebsites = () => {
        navigate("/web-design");
    };

    return (
        <React.Fragment>
            <Suspense fallback={<Loader />}>
                <div className="bg-black w-full flex flex-col items-start justify-center gap-20 p-5 lg:p-20 mb-20">
                    <div className="w-full flex flex-col items-center gap-5 text-center px-5 lg:px-40">
                        <p className="text-yellow-500 font-bold text-2xl">
                            {t("AboutUsExperienceDesign")}
                        </p>
                        <p className="text-white font-normal text-xl">
                            {t("AboutUsExperienceDescription")}
                        </p>
                        <UIButton
                            className={'w-[300px]'}
                            secondary
                            children={t("AboutUsStartProjectButton")}
                            action={goWebsites}
                        />
                    </div>
                    <div className="w-full">
                        <div className="text-center pb-10">
                            <p className="text-white font-normal text-xl">
                                {t("AboutUsTechnologies")}
                            </p>
                            <p className="text-white font-normal text-2xl">
                                {t("AboutUsTools")}
                            </p>
                        </div>
                        <div className="grid grid-cols-2 lg:grid-cols-6 gap-5 p-5 lg:p-20">
                            <div className="flex flex-col gap-5 items-center">
                                <img src={icon_001} alt="icon" ></img>
                                <p className="text-white font-normal text-base">
                                    Java Script
                                </p>
                            </div>
                            <div className="flex flex-col gap-5 items-center">
                                <img src={icon_002} alt="icon" ></img>
                                <p className="text-white font-normal text-base">
                                    PHP
                                </p>
                            </div>
                            <div className="flex flex-col gap-5 items-center">
                                <img src={icon_003} alt="icon" ></img>
                                <p className="text-white font-normal text-base">
                                    Phyton
                                </p>
                            </div>
                            <div className="flex flex-col gap-5 items-center">
                                <img src={icon_004} alt="icon" ></img>
                                <p className="text-white font-normal text-base">
                                    Git Lab
                                </p>
                            </div>
                            <div className="flex flex-col gap-5 items-center">
                                <img src={icon_005} alt="icon" ></img>
                                <p className="text-white font-normal text-base">
                                    Visual Studio Code
                                </p>
                            </div>
                            <div className="flex flex-col gap-5 items-center">
                                <img src={icon_006} alt="icon" ></img>
                                <p className="text-white font-normal text-base">
                                    Visual Studio
                                </p>
                            </div>
                            <div className="flex flex-col gap-5 items-center">
                                <img src={icon_007} alt="icon" ></img>
                                <p className="text-white font-normal text-base">
                                    Node
                                </p>
                            </div>
                            <div className="flex flex-col gap-5 items-center">
                                <img src={icon_008} alt="icon" ></img>
                                <p className="text-white font-normal text-base">
                                    Java
                                </p>
                            </div>
                            <div className="flex flex-col gap-5 items-center">
                                <img src={icon_009} alt="icon" ></img>
                                <p className="text-white font-normal text-base">
                                    Type Script
                                </p>
                            </div>
                            <div className="flex flex-col gap-5 items-center">
                                <img src={icon_010} alt="icon" ></img>
                                <p className="text-white font-normal text-base">
                                    Word Press
                                </p>
                            </div>
                            <div className="flex flex-col gap-5 items-center">
                                <img src={icon_011} alt="icon" ></img>
                                <p className="text-white font-normal text-base">
                                    HTML 5
                                </p>
                            </div>
                            <div className="flex flex-col gap-5 items-center">
                                <img src={icon_012} alt="icon" ></img>
                                <p className="text-white font-normal text-base">
                                    React
                                </p>
                            </div>
                            <div className="flex flex-col gap-5 items-center">
                                <img src={icon_013} alt="icon" ></img>
                                <p className="text-white font-normal text-base">
                                    Android
                                </p>
                            </div>
                            <div className="flex flex-col gap-5 items-center">
                                <img src={icon_014} alt="icon" ></img>
                                <p className="text-white font-normal text-base">
                                    iOS
                                </p>
                            </div>
                            <div className="flex flex-col gap-5 items-center">
                                <img src={icon_015} alt="icon" ></img>
                                <p className="text-white font-normal text-base">
                                    Angular
                                </p>
                            </div>
                            <div className="flex flex-col gap-5 items-center">
                                <img src={icon_016} alt="icon" ></img>
                                <p className="text-white font-normal text-base">
                                    Google Analytics
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </Suspense>
        </React.Fragment>
    )
}
export default UxDesign;