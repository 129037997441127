import React from "react";
import robot_003 from "../../../assets/images/robots/robot-app3.png";
import robot_008 from "../../../assets/images/robots/robot_008.png";
import UIButton from "../../atoms/UIButton";

const CarouselBlog = () => {

    return (
        <React.Fragment>
            <div className="py-40 px-5">
                <div className="w-full lg:w-10/12 xl:max-w-[1024px] h-[500px] md:h-[370px] lg:h-[250px] xl:h-[350px] m-auto flex flex-col-reverse lg:flex-row gap-4 p-10 items-center bg-gradient-cards from-brand-gray via-brand-gray to-brand-dark-gray border border-yellow-500 rounded-xl">
                    <div className="text-center w-full lg:w-1/2">
                        <p className="text-yellow-500 font-bold text-2xl md:text-4xl my-6">
                            Campañas de marketing
                        </p>
                        <p className="text-white font-normal text-xl">
                            Analiza el tráfico a tu sitio web, establece metas y caminos para dirigir a tus prospectos a convertirlos en clientes.
                        </p>
                        <div className="py-10">
                            <UIButton
                                primary
                                scrollToTop
                                children={'Mide el trafico a tu sitio'}
                            />
                        </div>
                    </div>
                    <div className="relative w-full md:w-5/12 lg:w-1/2 h-[550px] lg:h-[160px]">
                        <img className="absolute lg:left-1/2 lg:-translate-x-1/2 bottom-[-20px] lg:bottom-[-80px] w-[90%] md:w-[350px] lg:w-[480px] max-w-none m-auto" src={robot_003} alt="image" />
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}
export default CarouselBlog;